@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
.card{
    background-color: var(--theme-sidebar);
    padding: 25px;
    border-radius: 15px;
    font-family: 'Questrial', sans-serif;
}
.card span{
    font-size: x-large;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card h3{
    font-weight: 600;
    font-size: xx-large;
}
.card .header{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px 0;
}
.card .header svg{
    width: 30px;
    height: 30px;
}