
.header_page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.header_page .page_name{
    font-size: xx-large;
    font-weight: 700;
}
.search_form{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 20px;
}

.button{
    background-color: var(--theme-bg);
    color: var(--theme);
    border-radius: 4px;
    padding: 10px 8px;
}
.button:hover{
    background-color: var(--oppocity-bg);
}
.button:hover path{
    stroke: var(--theme-bg);
}
.button:hover i{
    color: var(--theme-bg);
}
@media (max-width: 576px) {
    .search_form > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}
@media (max-width: 365px) {
    .search_form > div:nth-child(2){
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.main_page{
    width: 100%;
    overflow-x: auto;
    margin-bottom: 200px;
}
.main_page .table_cap{
    overflow: auto;
    max-height: 550px;
    padding-right: 10px;
}
.main_page .table_cap::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
.main_page .table_cap::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
.main_page .table_cap::-webkit-scrollbar-thumb {
    background: #888;
}

.table_cap thead th{
    background-color: var(--theme-bg);
    position: sticky;
    top: 0;
}
.table_cap thead input{
    margin: 0 15px;
}
.main_page table{
    width: 100%;
    margin-top: 20px;
    color: var(--oppocity-bg);
    font-size: larger !important;
    
}
@media (max-width: 768px) {
    .main_page table{
        width: 600px;
    }

}
.main_page table th,
.main_page table td{
    text-align: -webkit-center;
    padding: 15px 0;
    border-bottom: 2px solid var(--theme-bg);
}
.main_page strong {
    white-space: nowrap;
    font-weight: 600;
}
.main_page a,
.main_page li{
    white-space: nowrap;
}
.main_page table th{
    font-weight: 100;
}
.main_page table thead tr {
    font-size: large;
}
.main_page table tr {
    font-size: medium;
}
.main_page tr  td:last-child{
    padding-right: 30px;
} 
.main_page table ul{
    list-style: none;
    margin: 0 20%;
}
.main_page table tr:hover{
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.main_page table tr:active{
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.main_page table p{
    cursor: text;
}
table .subject li:nth-child(1){
    color: var(--theme);
    font-size: large;
    font-weight: 600;
}
table svg{
    width: 25px;
    height: 25px;
}
.settings{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.checkbox{
    accent-color: var(--theme);
}
.header_content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.row.active{
    background-color: var(--selected);
}
.textField input{
    border-color: var(--theme) !important;
}
.textField fieldset{
    border-color: var(--theme) !important;
}