.group-forms-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 20px;
	justify-content: flex-start;
}




.formfield-group {
	min-width: 200px;
	width: 100%;
	height: 50px;
	gap: 10px;
	display: flex;
	align-items: center;
	padding: 0.7em;
	justify-content: center;
	background: var(--theme-sidebar);
	border-radius: 10px;
	box-shadow: 0.5px 0.5px 5px 0px rgba(0, 0, 0, 0.25);
}

.formfield-group.icon-right {
	flex-direction: row-reverse;
}

.formfield-group i {
	font-size: 1.2em;
}

.formfield-input[type=text] {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	background: transparent;
}

.formfield-select {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	border-radius: 0;
	background: var(--theme-sidebar);
}