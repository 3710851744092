.days{
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 4px;
    /* justify-content: space-around; */
}
.days span{
    padding: 6px 8px;
    font-size: medium;
    background: var(--theme-bg);
    color: var(--theme-color);
    border-radius: 8px;
}
.days.active span{
    background: var(--theme-sidebar);
}
.days span.active{
    background-color: var(--theme);
    color: #fff;
}