.worker_card{
    background: var(--theme-bg);
    padding: 15px 5px;
    border-radius: 15px;
    gap: 5%;
}
.worker_card button,
.worker_card .button{
    width: 100%;
    margin-top: 10px;
    font-size: small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.worker_card ul{
    font-size: 18px
}
.worker_card strong{
    font-size: 20px;
}

.bg_image {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    background-color: var(--theme-sidebar);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 10px;
}