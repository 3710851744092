@tailwind base;
@tailwind components;
@tailwind utilities;

.white-divider {
    background-color: white;
    width: 100%;
    height: 5px;
}

html.dark .white-divider {
    background: var(--theme-bg);
}
.budget_box.active .date_filter {
    display: flex;
}