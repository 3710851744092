.nav {
    width: calc(100% - 300px);
    height: 90px;
    background-color: var(--theme-sidebar);
    margin-left: 300px;
    padding: 0 120px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

    transition: width 1s ease-in-out !important;
    transition: position 1s ease-in-out !important;
    transition: top 0.3s !important;
    transition: left 0s !important;
    transition: transform 0s !important;
}
.page_name{
    font-size: xx-large;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.end_content {
    display: flex;
    align-items: center;
    gap: 20px;
}
.nav path {
    fill: var(--theme-color)
}
.nav .stroke path,
aside .stroke path{
    fill: none !important;
    stroke: var(--theme-color) !important;
}
.nav .toggle svg{
    fill: none;
}
svg text{
    font-size: 11px;
    fill: var(--theme-sidebar);
}



aside{
    position: fixed;
    top: 0;
    width: 300px;
    height: 100vh;
    background-color: var(--theme-sidebar);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);    
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    justify-content: space-between;
}
aside *{
    transition: all 0.5s ease !important;
}
.nav_items{
    margin-top: 45px;
}
.nav_items a{
    width: 220px;
    height: 50px;
    display: flex;
    padding: 15px 25px;
    border-radius: 15px;
    font-family: Poppins;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    background-color: var(--theme-bg);
}
.nav_items .controllers{
    width: 220px;
    height: 50px;
    display: none;
    padding: 15px 25px;
    border-radius: 15px;
    font-family: Poppins;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    background-color: var(--theme-bg);
    justify-content: space-around;
}
.nav_items .controllers div,
.nav_items .controllers a
{
    padding: 6px;
    border-radius: 50%;
    background-color: var(--theme-sidebar);
}
.nav_items .controllers a{
    width: unset;
    height: unset;
    margin: 10px 0;
}
.nav_items .controllers path
{
    fill: var(--theme-color);
}
.nav_items .controllers div:nth-child(3) circle{
    fill: var(--theme);
}

.nav_items a span{
    font-size: 20px;
}

.nav_items a.active,
.nav_items a:hover
 {
    background: var(--theme);
    color: #fff;
}
.nav_items a.active  .color_fill path,
.nav_items a:hover .color_fill path{
    fill: #ffffff;
}
.nav_items a.active .color_storke path,
.nav_items a:hover .color_storke path,
.nav_items a.active .color_storke circle,
.nav_items a:hover .color_storke circle
{
    stroke: #ffffff;
}

.nav_items a .color_fill path{
    fill: var(--theme-color);
}
.nav_items a .color_storke path,
.nav_items a .color_storke circle
{
    stroke: var(--theme-color);
}

.nav_items a.logout span{
    color: #D23F3F;
}
.nav_items a.logout path{
    fill: #D23F3F;
}
.nav_items a.logout:hover{
    background-color: #D23F3F;
}
.nav_items a.logout:hover span{
    color: #fff;
}
.nav_items a.logout:hover path{
    fill: #fff;
}
.nav .theme_control{
    width: 70px;
    height: 38px;
    background-color: var(--theme-bg);
    border-radius: 25px;
    display: flex;
    align-items: center;
    
}
.nav .theme_control.active .toggle{
    transform: translateX(30px);
}
.nav .toggle{
    width: 30px;
    height: 30px;
    background-color: var(--theme-sidebar);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}


@media (max-width: 1440px) {
    .nav{
        margin-left: 120px;
        width: calc(100vw - 120px);
        padding: 10px;
    }
    aside{
        width: 120px;
    }
    .nav_items {
        margin-top: 25px;
    }
    .nav_items a{
        width: unset;
        height: unset;
        display: flex;
        flex-direction: column;
        padding: 8px 5px;
        margin-bottom: 10px;
    
    }
    .nav_items a svg{
        width: 25px;
        height: 25px;
    }
    .nav_items a span{
        font-size: 15px;
    }
}
@media (max-width: 1024px) {
    .nav_items a{
        margin-bottom: 20px;
        padding: 10px 5px;
    
    }
    .nav_items a svg{
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 576px) {
    .nav{
        margin-left: 0;
        width: 100vw;
        padding: 0 25px;
        gap: 2.5px;
    }
    .nav .page_name{
        font-size: larger;
    }
    .nav svg{
        display: none;
    }
    aside{
        left: -500px;
        width: 250px;
        z-index: 99;
    }
    aside.active{
        left: 0;
    }
    .nav_items a{
        width: 220px;
        height: 50px;
        padding: 15px 25px;
        flex-direction: row;
    }
    .nav .theme_control{
        display: none;
    }
    .nav_items .controllers{
        display: flex;
    }

    
}
.page_content{
    display: flex;
    width: 100vw;
}
.page_content main{
    width: calc(100vw - 300px);
    margin-left: 300px;
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
}

@media (max-width: 1440px){
    .page_content main{
        width: calc(100vw - 150px);
        margin-left: 150px;
    }
}
@media (max-width: 576px){
    .page_content main{
        width: calc(100vw);
        margin-left: 15px;
    }
}
.premium {
    background-color: var(--theme);
    width: 250px;
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 15px;
    margin-top: 30px;
    padding: 15px 20px;
}
.premium span{
    font-size: 22px;

}
.premium .content{
    background-color: #FFF;
    position: relative;
    color: #000000;
    width: 135px;
    height: 32px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 10px;
}
.second_content {
    display: flex;
    gap: 10px;
    align-items: center;
}
@media (max-width: 1440px) {
    .premium {
        width: unset;
        height: unset;
        padding: 20px 25px;
        margin-top: 50px;
    }
    .premium span{
        display: none;
    }
    .premium .content{
        display: none;
    }
    .premium svg{
        width: 40px;
        height: 40px;
    }
    
}
@media (max-width: 576px) {
    .premium {
        width: 220px;
        padding: 20px 25px;
        margin-top: 50px;
    }
    .premium span{
        display: block;
        font-size: 18px;
    }
    .premium a{
        display: flex;
    }
    .premium svg{
        width: 25px;
        height: 25px;
    }
    
}
.bg_image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--theme-bg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 10px;
}
.extra_help{
    display: none;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 52%;
    transform: rotate(90deg) translateY(95px);
    z-index: 9;
}
.extra_help path{
    stroke: var(--theme-color);
}
@media (max-width: 576px) {
    .extra_help{display: flex;}    
}

.hide_background{
    display: none;
}
.hide_background.active{
    display: block;
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--oppocity-bg);
    opacity: 0.4;
    z-index: 99;
}


.change_color_modal{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    padding: 5px;
    border-radius: 25px;
    background-color: var(--theme-sidebar);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
}
.change_color_modal .dropdown__item{
    padding: 5px 10px;
    display: flex;
    gap: 15px;
    margin: 25px 0;
    border-radius: 15px;
}
.change_color_modal .dropdown__item.disable,
.change_color_modal .dropdown__item:hover
{
    opacity: 0.8;
    background-color: var(--theme-bg);
}
.mark{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}


.logo{
    display: none;
}
.logo  path{
    fill: var(--theme);
}
.logo_text{
    display: flex;
    align-items: center;
    gap: 10px;
}
.logo_text span{
    font-size: xx-large;    
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-left: 5px;
    white-space: nowrap;
}
.logo_text svg{
    display: block;
    width: 45px;
    height: 45px;
}
@media (max-width: 1440px){
    .logo_text span{
        display: none;  
    }
}
@media (max-width: 576px){
    .logo_text span{
        display: block;  
    }
    .logo{
        display: block;
    }
    .logo svg{
        display: block;
        width: 45px;
        height: 45px;
    }
    .logo_text{
        display: flex;
        gap: 5px;
    }
    .logo_text span{
        font-size: larger;    
    }
    .logo_text svg{
        width: 35px;
        height: 35px;
    }
}

.notification{
    position: absolute;
    top: 70px;
    right: 20px;
    z-index: 100;
    width: 500px;
    height: calc(100vh - 80px);
    background-color: var(--theme-sidebar);
    border-radius: 25px;
    box-shadow: 0px 1px 11px 9px rgba(0, 0, 0, 0.15);
}