.card{
    padding: 10px 25px;
    background-color: var(--theme-sidebar);
    border-radius: 15px;
    width: clamp(200px, 100%, 350px);
    position: relative;
}
.card h3{
    margin: 5px 10px;
    font-weight: 600;
}
.card .drop,
.card .from{
    position: absolute;
    left: 0;
    background-color: var(--theme-sidebar);
    z-index: 6;
    padding: 10px 25px;
    margin-top: 10px;
    padding-top: 10px;
    font-size: large;
    border-top: dashed  3px var(--theme-bg);
    display: none;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}
.card.active,
.card.from_activate
{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.card.active .drop{
    background-color: var(--theme-sidebar);
    width: 100%;
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow:  0.5px 0.5px 5px 0px var(--shadow);
}
.card.from_activate .from{
    background-color: var(--theme-sidebar);
    width: 100%;
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow:  0.5px 0.5px 5px 0px var(--shadow);
}
.card.active i.toggle{
    transition: all 1s ease-in;
    transform: rotate(180deg);
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
.drop h5{
    color: var(--green);
}

.group_input{
    width: 100%;
    font-size: x-large;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 3px solid var(--theme-bg);
}
.group_input input{
    width: 80%;
    outline: none;
}